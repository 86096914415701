import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
require("firebase/firestore");

// const firebaseConfig = {
//   apiKey: "AIzaSyCN5f6bfjg78YwqacyC80dAvlMEBoOPPnc",
//   authDomain: "affy-4bcb9.firebaseapp.com",
//   projectId: "affy-4bcb9",
//   storageBucket: "affy-4bcb9.appspot.com",
//   messagingSenderId: "267969709238",
//   appId: "1:267969709238:web:dabe4c6144b105e3545217",
//   measurementId: "G-N1CWHMG3JH"
// };
const firebaseConfig = {
  apiKey: "AIzaSyCN5f6bfjg78YwqacyC80dAvlMEBoOPPnc",
  authDomain: "affy-4bcb9.firebaseapp.com",
  projectId: "affy-4bcb9",
  storageBucket: "affy-4bcb9.appspot.com",
  messagingSenderId: "267969709238",
  appId: "1:267969709238:web:dabe4c6144b105e3545217",
  measurementId: "G-N1CWHMG3JH"
}
firebase.initializeApp(firebaseConfig);
firebase.firestore();
var storage = firebase.storage();
var storageRef = storage.ref();
const db = firebase.firestore();
const auth = firebase.auth();
const increment = firebase.firestore.FieldValue.increment(1);
const decrement = firebase.firestore.FieldValue.increment(-1);
export { db, auth, increment, decrement, storageRef };
