import { Card } from "primereact/card";
import { FaPen } from "react-icons/fa";
import { Checkbox } from 'primereact/checkbox';
import {style} from './CardStyles'        


export default function TermsCard(props) {

  return (
    <Card style={style.formCard}>
      <FaPen size={"25"} style={style.headerIcon} />

      <div
        className="details-card"
        style={style.cardBody}
      >
        <h2 style={style.cardHeader} >Terms of Service</h2>
        <div style = {style.cardSubBody}>

        <div style={{display:"flex", alignItems:"center",}}>
          <Checkbox inputId="terms" style={{display:"inline", marginRight:"10px"}} onChange={e => props.update({agreeTerms:e.checked})} checked={props.state.agreeTerms}></Checkbox>
          <label htmlFor="terms">  I have read and agree to the <a href="" target="_blank"> Terms of Service Agreement</a> </label>
        </div>
        </div>

      </div>
    </Card>
  );
}
