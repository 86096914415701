import React, { useState } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../ManageCampaign.css";
import { Affiliate } from "../../../data/models/CampaignModels";
import { db } from "../../../firebase";
import {  addAffiliateToCampaign } from "../../../data/CampaignService";
interface Props {
  affiliateArray:[];
  title: string;
  url: string;
  description: string;
  budget: number;
  cpc: number;
  attachments: any[];
  agreeTerms: boolean;
  status: string;
  allowedAffiliateIds: [];
  affiliates: [];
  clicks: 0;
  owner: string;//"rZZqz3ggegcboepho5RIiDtwJUb2";
  max: number;//0;
  id: string;//"on0DBk3Ax8g5gy2BstfU";
}
export default function ManageAffiliatesCard(props:Props) {
  console.log(`ManageAffiliatesCard:props, ${JSON.stringify(props)}`);
  const [email, setEmail] = useState("");
  const [affiliates, setAffiliates] = useState(props.affiliateArray);

  const handleEmailInvite = async () => {
    //for sending email
    const tempAffiliate: Affiliate = {
      id: null,
      email: email,
      status: "Pending",
      totalClicks: 0,
      times: [],
    };
    
    const affiliateState = [...affiliates];

    await db.collection("mail").add({
      to: email,
      message: {
        subject: "Campaign invitation to Affy!",
        html: "You've been invited to join an affiliate campaign on <a href='https://www.affy.xyz'>Affy</a>!",
      },
    });


    affiliateState.push(tempAffiliate);
    // // update backend
    addAffiliateToCampaign(props, tempAffiliate);
    //addAffiliateTempCollection(props.id,tempAffiliate.email,"Pending")
    // udpate auth context
    props.affiliateArray.push(tempAffiliate);
    // update local view
    setAffiliates(affiliateState);

    setEmail("");
  };

  return (
    <Card style={{ padding: "0px 5px" }}>
      <div
        style={{
          marginTop:"-10px",
          fontSize: "1.5rem",
          fontWeight: "700",
          justifySelf: "left",
          marginBottom:'5px'
        }}
      >
        Send an email invitation
      </div>
      {/* <div>
        Affiliates you invite to the campaign are approved to market and be
        compensated.
      </div> */}
      <div style={{ marginBottom: "10px",maxWidth:550 }}>
      Affiliates you invite to the campaign are approved to market and be
        compensated.
        Once invited, affilaites cannot be removed.
      </div>
      <InputText
        style={{ marginRight: "10px",height:40,minWidth:325 }}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button
        label="Invite"
        size="small"
          
          style={{ marginRight: "20px",height:40,fontSize:12 }}
        onClick={handleEmailInvite}
        onChange={(e) => setEmail(e.target.value)}
      />
      <div
        style={{
          fontSize: "1.5rem",
          fontWeight: "700",
          justifySelf: "left",
          margin: "30px 0px",
          marginBottom:'10px'
        }}
      >
        Affiliates
      </div>

      <DataTable value={affiliates}>
        <Column
          field="email"
          header="Email"
          style={{ width: "25%" }}
        ></Column>
        <Column
          field="status"
          header="Status"
          style={{ width: "25%" }}
        ></Column>
        <Column
          field="totalClicks"
          header="Clicks"
          style={{ width: "25%" }}
        ></Column>
      </DataTable>
    </Card>
  );
}
