import React, { Component } from "react";
import { Button } from 'primereact/button';

import Navbar from '../../components/Navbar/Navbar';
import { Link, useHistory } from "react-router-dom";
import Text from "../../constants/Text";
import landingImage from "../../assets/images/landingImage.jpg"
import AffiliatesTraffic from "../../assets/images/AffiliateTraffic.png"
import LargeAffiliateView from "../../assets/images/LargeAffiliateView.png"
import OnTime from "../../assets/images/OnTime.png"


import { FiMail } from "react-icons/fi";
import { BsPlug } from "react-icons/bs";
import { MdTrackChanges } from "react-icons/md";
import { VscMultipleWindows } from "react-icons/vsc";

export default class LandingScreen extends Component {
  render() {
    return (
      <div style ={{backgroundColor:"#282b30"}}>
       
        
      <Navbar bgColor="#282b30"/>

        <div style={{backgroundColor:"#282b30",  color:"#d9e3ea"}}>
          <div style={{textAlign:"center",}}>
            <h1 style={{ fontWeight:800}}>Influencer Pay-Per-Click Marketing</h1>
            <h6 style={{}}>Create affiliate campaigns, view metrics for affiliates, and pay based on value added</h6>
            <div style={{display:"flex", justifyContent:"center", height:"30px", marginTop:"20px"}}>
              <Link to="/signup">
                <Button style={{borderRadius:"0px", color: "#d9e3ea"}}  label="Get Started" />
              </Link>
            </div>
          </div>

          <div style={{padding:"5% 10%",textAlign:"center"}}>
            <img src={landingImage} style={{ filter: "grayscale(100%)", width:"90%"}}/>
          </div>

          <div style={{padding:"0% 15%", paddingTop:"15px", textAlign:"center"}}>
            <h1 style={{fontWeight:800}}>Invite affiliates you want to promote your product/brand.</h1>
            {/* <div style={{fontSize:"1.1rem", paddingTop:"5px"}}>Invite only based campaigns allow you to compensate affiliates</div> */}
          </div>

          <div style={{padding:"0% 5%", display:"flex", justifyContent:"center", alignItems:"center", flexWrap: "wrap", alignContent:"space-around"}}>
           <div style={{marginTop:"50px", alignItems:"center", display:"flex", flexDirection:"column", flex: "1 0 250px", padding:"0px 10px"}}>
            <div style={{margin:"5px", backgroundColor:"#6366f1", borderRadius:"30px", width:"60px", height:"60px", display:"flex", justifyContent:"center", alignItems:"center" }}>
              <FiMail size={30} color="white"/>
            </div>
            <div style={{margin:"5px", fontSize:"1.8rem", fontWeight:700}}>Invite Only</div>
            <div style={{margin:"5px", textAlign:"center", fontSize:"1.25rem"}}>Only invited affiliates are elgible for compensation per click</div>
           </div>
           <div style={{marginTop:"50px", alignItems:"center", display:"flex", flexDirection:"column", flex: "1 0 250px", padding:"0px 10px"}}>
            <div style={{margin:"5px",backgroundColor:"#6366f1", borderRadius:"30px", width:"60px", height:"60px", display:"flex", justifyContent:"center", alignItems:"center" }}>
              <VscMultipleWindows size={30} color="white" />
            </div>
            <div style={{margin:"5px", fontSize:"1.8rem", fontWeight:700}}>Multiple Campaigns</div>
            <div style={{margin:"5px",textAlign:"center", fontSize:"1.25rem"}}>The dashboard lets you manage all your campaings in once place</div>
           </div>
           <div style={{marginTop:"50px", alignItems:"center", display:"flex", flexDirection:"column", flex: "1 0 250px", padding:"0px 10px"}}>
            <div style={{margin:"5px",backgroundColor:"#6366f1", borderRadius:"30px", width:"60px", height:"60px", display:"flex", justifyContent:"center", alignItems:"center" }}>
              <BsPlug size={30} color="white" />
            </div>
            <div style={{margin:"5px", fontSize:"1.8rem", fontWeight:700}}>Plug-in-Play</div>
            <div style={{margin:"5px",textAlign:"center", fontSize:"1.25rem"}}>Requires no changes or access needed to targeted site.</div>
           </div>
           <div style={{marginTop:"50px", alignItems:"center", display:"flex", flexDirection:"column", flex: "1 0 250px", padding:"0px 10px"}}>
            <div style={{margin:"5px",backgroundColor:"#6366f1", borderRadius:"30px", width:"60px", height:"60px", display:"flex", justifyContent:"center", alignItems:"center" }}>
              <MdTrackChanges size={30} color="white"/>
            </div>
            <div style={{margin:"5px", fontSize:"1.8rem", fontWeight:700}}>Track Traffic</div>
            <div style={{margin:"5px",textAlign:"center", fontSize:"1.25rem"}}>View clicks and other metrics for each affiliate</div>
           </div>
          </div>

          <div style={{ margin:"5% 5%", height:"1px", width:"90%", backgroundColor:"#6366f1", opacity:"0.25"}}/>
      
          <div style={{padding:"0% 10%", textAlign:"center"}}>
            <h1 style={{fontWeight:800}}>Are you reaching next generation?</h1>
            <div style={{fontSize:"1.35rem", paddingTop:"7.5px"}}>Content consumtion is changing. So should you. Older marketing approaches exclude newer generations. Affiliate marketing is the most effective way to promote your brand and build your online presence.</div>
          </div>

          <div style={{padding:"7.5% 5%", textAlign:"left", display:"flex", alignContent:"space-between", flexWrap: "wrap" }}>
            <div style={{margin:"0px 20px", flex:"1 0 50%"}}>
              <div style={{fontSize:"1.75rem", color:"#6366f1", fontFamily:"Morgan-Chalk"}}>More speed. Less spend</div>
              <div style={{fontSize:"2.2rem", fontWeight:700}}>Keep projects on schedule</div>
              <div style={{fontSize:"1.25rem", margin:"10px 0px"}}>Negotiating promotional content with YouTubers leads to time wasted and often times money gone to under performing promotions. Affy improves affiliate marketing by</div>
              <div style={{margin:"5px 0px", fontSize:"1.15rem"}}>✔️ Compensating based on performance</div>
              <div style={{margin:"5px 0px", fontSize:"1.15rem"}}>✔️ Eliminating need for contractual obligations and payment</div>
              <div style={{margin:"5px 0px", fontSize:"1.15rem"}}>✔️ Eliminating need to negotiate on a per affiliate basis </div>
            </div>
            <div style={{margin:"0 20px", flex:"1 0 40%"}}>
            <img src={OnTime} style={{  width: 'auto', height: '390px'}}/>
            </div>
          </div>

          <div style={{padding:"0% 5% 7.5% 5%", textAlign:"left", display:"flex", alignContent:"space-between", flexWrap: "wrap"}}>
            <div style={{margin:"0 20px", flex:"1 0 40%"}}>
            <img src={LargeAffiliateView} style={{width: 'auto', height: '390px'}}/>

              {/* <img style={{width:"375px"}} src="https://affy.xyz/assets/features-03-image-01-a6309655.png"></img> */}
            </div>
            <div style={{margin:"0px 20px", flex:"1 0 50%"}}>
              <div style={{fontSize:"1.75rem", color:"#6366f1", fontFamily:"Morgan-Chalk"}}>Metrics</div>
              <div style={{fontSize:"2.2rem", fontWeight:700}}>Know which affiliates generate traffic</div>
              <div style={{fontSize:"1.25rem", margin:"10px 0px"}}>Affy tracks wich affiliates are generating traffic. Allowing campaign managers to know what the most affective promotions of there site are. Gaining valuable insight such as</div>
              <div style={{margin:"5px 0px", fontSize:"1.15rem"}}>✔️ When traffic is being generated</div>
              <div style={{margin:"5px 0px", fontSize:"1.15rem"}}>✔️ What affiliate's individual contributions to the campaign are</div>
              <div style={{margin:"5px 0px", fontSize:"1.15rem"}}>✔️ Overall clicks generated through Affy campaign</div>
            </div>
           
          </div>

          <div style={{ margin:"0% 5%", height:"1px", width:"90%", backgroundColor:"#6366f1", opacity:"0.25"}}/>


          <div style={{padding:"5% 5%", textAlign:"left", display:"flex", alignItems:"center",  }}>
            <div style={{ textDecoration: "none", fontSize: 50, margin: "0px 20px"}} className="navbar-brand logo-image Logo">Affy</div>
            <div style={{textAlign:"left", display:"flex", flexDirection:"column", alignItems:"flex-start" }}>
              <div style={{ textDecoration: "none", fontSize: 20, margin: "0px 20px"}}> Affy provides creators/influencers with an additional method of receiving revenue.</div>
              <div style={{ textDecoration: "none", fontSize: 20, margin: "0px 20px"}}> Join now as a creator/influencer to receive invites to paid campaigns.</div>          
            </div>
          </div>

        </div>
        
        

        </div>

    );
  }
}
