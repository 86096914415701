export const style = {
    cardContainer:{ textAlign: "start", margin: "0px 80px" ,marginTop:-30},
    formCard:{
      position: "relative",
      maxWidth: '800px',
      minWidth: '525px',
      margin: '0 auto',
    },
    headerIcon:{
       position: "absolute", top: "31px", left: "25px" 
    },
    cardHeader:{
      marginLeft:-10
    },
    cardInput:{
      width:485
    },
    cardSubBody:{marginLeft:-55},
    cardBody:{ textAlign: "start", margin: "0px 60px" ,marginTop:-30}
  }