import Navbar from "../../components/Nav/Navbar";
import { useHistory } from 'react-router-dom';
import CampaignCard from "../../components/Cards/CampaignCard";
import Panel from '../../components/Panels/Panel'
import CommunicationPanel from "../../components/Panels/CommunicationPanel";
import AffiliateCard from "../../components/Cards/AffiliateCard";
import { AffiliatedCampaign, ManagedCampaign } from "../../data/models/CampaignModels";
import PaymentCard from "../../components/Cards/PaymentCard";
import PanelSingleCard from "../../components/Panels/PanelSingleCard";
import Lottie from "react-lottie";
import onlineShopperAnim from "../../lotties/online-shopper.json";
import {useAuth} from '../../contexts/AuthContext';
import { useState, useEffect } from "react";
import { db } from "../../firebase";
import { screenStyles } from "../ScreenStyles";
import ToastUtil from "../../components/ToastUtil.js/ToastUtil";




const defaultOptions = {
  loop: true,
  autoplay: false,
  animationData: onlineShopperAnim,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
export default function DashboardScreen() {

  const history = useHistory();
  const {logout,user} = useAuth();
  const[localUser,updateLocalUser] = useState();
  console.log('Dashboard:user',user)

  return (
    <div style={{ flex:1,paddingTop: "80px",backgroundColor:"#EFEFEF", width: '100%',
    height: '100%', }}>
      <Navbar
        leftClick={() => {
          console.log("hello");
          history.push("/dashboard")
        }}
        rightClick={async () => {
          console.log("signout");
          await logout();
          history.push("/")
        }}
        pageTitle={"Dashboard"}
        rightNavTitle={"Signout"}
      />
      <div style = {screenStyles.body}>

    
      {/* <PanelSingleCard title = {"Earned"} clicks = {1000} balance={2000} component={PaymentCard}/> */}
      <CommunicationPanel data = {user.communications} title = {"Communications"} component={CampaignCard} emptyMessage={"No Communications"}/>
      
      
      <Panel data = {user.userCampaigns} buttonHandler = {()=>{history.push("/newCampaign") }}  buttonTitle={"New Campaign"} title = {"My Campaigns"} component={CampaignCard} emptyMessage={"No Campaings"}/>

     <Panel data = {user.affiliatedCampaigns} title = {"Affiliated Campaigns"} component={AffiliateCard} emptyMessage={"No affiliated campaigns"} />
      <div style={{padding:'80px'}}></div>
      <div>
          <Lottie
            style={{ marginBottom: -50 }}
            options={defaultOptions}
            height={500}
            width={500}
          />
        </div>
        </div>
    </div>
  );
}
