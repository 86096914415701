const DEFAULT_USERNAME = "Click to add a username";
const DEFAULT_BIO = "Click to add a bio";
const Text = {
    DEFAULT_BIO,
    DEFAULT_USERNAME,
    PROFILE_SCREEN_HEADLINE: 'Shop Online, Fund Your ❤️ Creators',
    PROFILE_SCREEN_SUBHEADING: '',
    LANDING_SCREEN_HEADLINE: '',
}
export const COLLECTION = {
    ADMIN : "admin",
    CAMPAIGNS:"campaigns",
    AFFILIATES:"affiliates",
    MAIL:"mail"

}
export default Text;