import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { Dialog } from "primereact/dialog";

import { AiOutlineClose } from "react-icons/ai";

export default function EditModal(props) {
  const [header, setHeader] = useState(props.state.title);
  const [url, setUrl] = useState(props.state.url);

  const [description, setDescription] = useState(props.state.description);
  const [status, setStatus] = useState(props.state.status);

  const toast = useRef(null);

  const onUpload = () => {
    toast.current.show({
      severity: "info",
      summary: "Success",
      detail: "File Uploaded"
    });
  };

  const handleDelete = () => {
    console.log("delete attachment");
  };

  const handleSave = () => {
    console.log("handle save");
    props.updateState({title:header,description:description,status:status,url:url})
  };

  console.log("Edit modal render method running");
  return (
    <Dialog
      header="Edit Campaign Details"
      visible={props.visible}
      style={{ textAlign: "left" }}
      onHide={() => props.setVisible(false)}
    >
      <div style={{ margin: "50px", fontSize: "25px", fontWeight: "700" }}>
        <div style = {{}}>Header</div>
        <InputText  style = {{width:'300px'}}value={header} onChange={(e) => setHeader(e.target.value)} />
        <div style = {{marginTop:10}}>Description</div>
        <InputTextarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          rows={5}
          cols={30}
        />
        <div style = {{}} >Url</div>
        <InputText style = {{width:'300px'}} value={url} onChange={(e) => setUrl(e.target.value)} />
        {/* <div style = {{marginTop:10}} >Attachments</div> */}
        <Toast ref={toast}></Toast>
        {/* <FileUpload
          mode="basic"
          name="demo[]"
          url="/api/upload"
          accept="image/*"
          maxFileSize={1000000}
          onUpload={onUpload}
          auto
          chooseLabel="Upload"
        /> */}
        {/* <div style={{ display: "flex" }}>
          {props.attachments.map((attachment) => {
            return (
              <span
                key={attachment.name + attachment.link}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <a href={attachment.link}>{attachment.name}</a>{" "}
                <AiOutlineClose
                  style={{ color: "red" }}
                  onClick={handleDelete}
                />
              </span>
            );
          })}
        </div> */}
        <br />
        <Button label="Save"  style={{display: "block", margin: '0 auto',width:'95%',marginBottom:'-80px',marginTop:80}} onClick={handleSave} />
        <br />
      </div>
    </Dialog>
  );
}
