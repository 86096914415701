import { db } from "../firebase";
import firebase from "firebase";
import { Communication } from "./models/UserModel";
import {COLLECTION} from "../constants/Text";
import { Affiliate, Campaign } from "./models/CampaignModels";

export async function update(collection:string,doc:string,value) {
  try{
    await db.collection(collection).doc(doc).update(value);

  } catch(error){
      console.log(`firebase update failed with error`,error)
  }
}
export async function addAllowedId(campaignId, uid) {
  return db
    .collection(COLLECTION.CAMPAIGNS)
    .doc(campaignId)
    .update({
      allowedAffiliateIds: firebase.firestore.FieldValue.arrayUnion(uid),
    });
}

export async function acceptInvite(campaignId, affiliate) {
  console.log("acceptInvite", campaignId, affiliate )
  addAllowedId(campaignId, affiliate.uid);
  // add the id of campaignId under the accepting affiliate
  addAffiliatedCampaign(affiliate.email, campaignId);
  // update status on affiliate collection
  addAffiliateCollection(campaignId, affiliate, "Accepted");
  // delete temp collection object stored by email
  removeAffiliateCollection(campaignId, affiliate.email);
  removeAffiliateCommunication(campaignId, affiliate.email)
}

export async function declineInvite(campaignId, affiliate) {
  console.log("declineInvite", campaignId, affiliate )
  addAffiliateCollection(campaignId, affiliate, "Declined");
  // delete temp collection object stored by email
  removeAffiliateCollection(campaignId, affiliate.email);
  removeAffiliateCommunication(campaignId, affiliate.email)
}

export async function getCampaign(campaignId){
  return await (await db.collection(COLLECTION.CAMPAIGNS).doc(campaignId).get()).data()
}

export async function removeAffiliateCommunication(campaignId, affiliateEmail){
  const docRef = db.collection(COLLECTION.ADMIN).doc(affiliateEmail)
  const data = await (await docRef.get()).data()
  console.log("removeAffiliateCommunication:doc", data)
  const newCommunications = data.communications.filter((communication)=> campaignId != communication.campaignId)
  docRef.update({"communications" : newCommunications})
}
export async function addQR(campaignId){
  const docRef = db.collection(COLLECTION.CAMPAIGNS).doc(campaignId);
  const subCollectionRef = docRef.collection(COLLECTION.AFFILIATES);
  subCollectionRef
    .add({
      status: "Free",
      email: "QR",
      totalClicks: 0,
      times: [],
    })
    .then((docRef) => {
      console.log('Created campaign QR doc',docRef)
      addAllowedId(campaignId, docRef.id);
      console.log('docRef ID',docRef.id)
      db.collection(COLLECTION.CAMPAIGNS).doc(campaignId).update({ QR: docRef.id })
    })
    .catch((error) => {
      console.error("addQR Error creating document: ", error);
    });
}
export async function removeAffiliateCollection(campaignId, affiliateId) {
  const docRef = db.collection(COLLECTION.CAMPAIGNS).doc(campaignId);
  const subCollectionRef = docRef.collection(COLLECTION.AFFILIATES);
  const subDocRef = subCollectionRef.doc(affiliateId); // in case of acceptInvite this is eamil

  subDocRef
    .delete()
    .then(() => {
      console.log("Sub-document successfully deleted!");
    })
    .catch((error) => {
      console.error("Error removing sub-document: ", error);
    });
}
export async function addAffiliatedCampaign(affiliateEmail, campaignId) {
  console.log("addAffiliatedCampaign", affiliateEmail, campaignId)
  await db
    .collection(COLLECTION.ADMIN)
    .doc(affiliateEmail)
    .update({
      affiliatedCampaignIds:
        firebase.firestore.FieldValue.arrayUnion(campaignId),
    });
}
export async function addAffiliateCollection(campaignId, affiliate, status) {
  console.log("addAffiliateCollection",campaignId, affiliate, status)
  const docRef = db.collection(COLLECTION.CAMPAIGNS).doc(campaignId);
  const subCollectionRef = docRef.collection(COLLECTION.AFFILIATES);
  const subDocRef = subCollectionRef.doc(affiliate.uid);
  subDocRef
    .set({
      status: status,
      email: affiliate.email,
      totalClicks: 0,
      times: [],
    })
    .then(() => {
      console.log("set addAffiliateCollection Document updated successfully");
    })
    .catch((error) => {
      console.error("addAffiliateCollection Error creating document: ", error);
    });


}
export async function addAffiliateTempCollection(
  campaignId,
  affiliateEmail,
  status
) {
  const docRef = db.collection(COLLECTION.CAMPAIGNS).doc(campaignId);
  const subCollectionRef = docRef.collection(COLLECTION.AFFILIATES);
  const subDocRef = subCollectionRef.doc(affiliateEmail);
  subDocRef
    .set({
      status: status,
      email: affiliateEmail,
      totalClicks: 0,
      times: [],
    })
    .then(() => {
      console.log("set addAffiliateCollection Document updated successfully");
    })
    .catch((error) => {
      console.error("addAffiliateCollection Error creating document: ", error);
    });
}
export async function addNewCampaign(campaign, currentUserEmail) {
  console.log("addNewCampaign:campaign", campaign);
  var id = '';
  return new Promise<Campaign>((resolve) => {
    db.collection(COLLECTION.CAMPAIGNS)
      .add(campaign)
      .then(async (docRef) => {
        console.log("addNewPromotion:docRef.id", docRef.id);
        id = docRef.id;
        campaign.id = id;
        db.collection(COLLECTION.CAMPAIGNS).doc(id).update({ id: id });
        db.collection(COLLECTION.ADMIN)
          .doc(currentUserEmail)
          .update({
            campaignIds: firebase.firestore.FieldValue.arrayUnion(id),
          });
        await addQR(id);
        
      }).finally(async ()=>{
        const campaign = await db.collection(COLLECTION.CAMPAIGNS).doc(id).get();
        console.log('addNewCampaign:finally',campaign.data())
        resolve(campaign.data())
      })
  });
}
export async function terminateStatus(campaignId){
  const docRef = db.collection(COLLECTION.CAMPAIGNS).doc(campaignId);
}
export async function addAffiliateToCampaign(
  campaign: Campaign,
  affiliate: Affiliate
) {
  // update the campaign affiliate Collection
    addAffiliateTempCollection(campaign.id,affiliate.email,"Pending");
    sendInviteCommunication(campaign, affiliate.email);
}
export function sendInviteCommunication(campaign, affiliateEmail) {
  const docRef = firebase.firestore().collection(COLLECTION.ADMIN).doc(affiliateEmail);
  const message: Communication = {
    type: "Invite", //"Invite",
    title: `You've been invited to be an affiliate for ${campaign.title}`, //'Title',
    budget: 0, //1000,
    cpc: 0, //1,
    campaignId: campaign.id,
  };
  docRef.set({communications: firebase.firestore.FieldValue.arrayUnion(message)},{ merge: true })
}

export async function getAllAffiliatesOnCampaign(campaign){
  let subCollectionDocs = [];
  console.log(`getAllAffiliatesOnCampaign:campaignId`,campaign.id)

  const docRef = db.collection(COLLECTION.CAMPAIGNS).doc(campaign.id);
  const subCollectionRef = docRef.collection(COLLECTION.AFFILIATES);
  let querySnapshot = await subCollectionRef.get();

  // Get all the documents in the sub-collection
    console.log('querySnapshot',querySnapshot)
    querySnapshot.forEach((doc) => {
      console.log(`getAllAffiliatesOnCampaign:affiliate`,doc.data())
      // Push each document into the array
       subCollectionDocs.push(doc.data());
  })
    // Use the array of documents here
  campaign.affiliateArray =  subCollectionDocs;
 
}


export async function collectAffiliates(campaigns){
  let promises = [];
  campaigns.forEach(campaign => {
    promises.push( getAllAffiliatesOnCampaign(campaign))
  })
  await Promise.all(promises)
}
export async function getAllUserCampaigns(email){
  console.log(`getAllUserCampaigns:email`,email)
  const docRef = await db.collection(COLLECTION.ADMIN).doc(email).get();
  const userData = docRef.data();
  let campaigns = await getCampaigns(userData.campaignIds)
  return campaigns;
}

export async function getCampaigns(campaignIds){
  console.log(`getCampaigns:campaignIds, ${campaignIds}`)
  let promises = []
  campaignIds.forEach(id =>{
    promises.push(db.collection(COLLECTION.CAMPAIGNS).doc(id).get())
  })
  let responses = await Promise.all(promises);
  let campaigns = [];

  responses.forEach((campaign) => {
    console.log("getUser:affiliatedCampaignArray:campaign.data()", campaign.data());
    campaigns.push(campaign.data());
  });
  console.log(`getCampaigns:campaigns, ${campaigns}`)

  return campaigns
}
