import { useState } from "react";

import Navbar from "../../components/Nav/Navbar";
import { useHistory } from "react-router-dom";
import PanelSingleCard from "../../components/Panels/PanelSingleCard";
import BudgetCard from "../../components/Cards/BudgetCard";
import DetailsCard from "../../components/Cards/DetailsCard";
import TermsCard from "../../components/Cards/TermsCard";
import AttachmentsCard from "../../components/Cards/AttachmentsCard";
import { useAuth } from "../../contexts/AuthContext";
import { Button } from "primereact/button";
import { addNewCampaign } from "../../data/CampaignService";
import { Campaign } from "../../models";
import { RegExpChecks } from "../../utils/RegEx";
export default function NewCampaignFormScreen() {
  const { currentUser, user, setUser } = useAuth();
  console.log("NewCampaignFormScreen:currentUser", currentUser);
  const initial: Campaign = {
    title: "",
    url: "",
    description: "",
    budget: 0,
    cpc: 0,
    attachments: [],
    agreeTerms: false,
    status: "Active",
    allowedAffiliateIds: [],
    affiliateArray: [],
    clicks: 0,
    owner: currentUser.uid,
    max: 0,
    id:null,
    times:[]
  };
  const [campaign, updateCampaign] = useState(initial);
  const [canSubmit,updateCanSubmit] =  useState(true)
  const campaignUpdates = (value) => {
    console.log("campaignUpdates value", value);
    console.log("campaignUpdates campaign", campaign);
    let newValue = { ...campaign, ...value };
    console.log("campaignUpdates newValue", newValue);

    updateCampaign(newValue);
    updateCanSubmit(disableSubmit(newValue))
  };
  const disableSubmit=(value:any)=>{
    console.log(`disableSubmit:value`,value)
    console.log(`RegExpChecks.isValidUrl(value.url)`,RegExpChecks.isValidUrl(value.url))
    return !value.description || !value.title || !value.url || !value.agreeTerms 
  }
  const history = useHistory();
  return (
    <div
      style={{
        flex: 1,
        paddingTop: "80px",
        backgroundColor: "#EFEFEF",
        width: "100%",
        height: "100%",
      }}
    >
      <Navbar
        leftClick={() => {
          console.log("hello");
          history.push("/dashboard");
        }}
        pageTitle={"New Campaign"}
      />
      <PanelSingleCard
        update={campaignUpdates}
        state={campaign}
        component={DetailsCard}
      />
      {/* <PanelSingleCard
        update={campaignUpdates}
        state={campaign}
        component={BudgetCard}
      /> */}
      <PanelSingleCard
        update={campaignUpdates}
        state={campaign}
        component={AttachmentsCard}
      />
      <PanelSingleCard
        update={campaignUpdates}
        state={campaign}
        component={TermsCard}
      />

      <div
        style={buttonContainer}
      >
        <Button
          onClick={() => history.push("/dashboard")}
          label="Cancel"
          style={buttonStyles}
        />
        <Button
          style={buttonStyles}
          label="Submit"
          disabled={canSubmit}
          onClick={() => {
            console.log("NewCampaignFormScreen:campaign", campaign);
            addNewCampaign(campaign, currentUser.email).then((campaign) => {
              user.userCampaigns.push(campaign);
              setUser(user);
              history.push("/dashboard");
            });
          }}
        />
      </div>

      <div style={{ padding: "200px" }}></div>
    </div>
  );
}
const buttonStyles = {
  marginRight:70,
  marginLeft:80,
  marginTop:20,
  minWidth:175
};
const buttonContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  maxWidth: '950px',
      minWidth: '100px',
  margin: '0 auto',
};