import {  useState } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import TerminateModal from "./TerminateModal";
import EditModal from './EditModal'
import {update} from '../../../data/CampaignService'
import { useAuth } from "../../../contexts/AuthContext";
import {style} from '../CardStyles';
import { COLLECTION } from "../../../constants/Text";
const colors = {
  Active: "green",
  Ended: "red",
  Terminated: "blue"
};

export default function ManageDetailsCard(props) {
  console.log('ManageDetailsCard:props',props)
 const {updateCampaign} = useAuth();
  const [terminateModalVisible, setTerminateModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [campaignState,UpdateState] = useState({status:props.status,title:props.title,description:props.description,url:props.url});
  const updateDetails = (value) => {
    // create a new reference 
    console.log('ManageDetailsCard:props',props)
    console.log('ManageDetailsCard:update:value',value)
    let theUpdate = {...campaignState,...value}
    console.log('ManageDetailsCard:update:theUpdate',theUpdate)
    UpdateState(theUpdate);
    update(COLLECTION.CAMPAIGNS,props.id,value)
    updateCampaign(theUpdate);
    setEditModalVisible(false)
   
  }
  const terminate = async () => {
    let value = {status:'Terminated'}
    console.log(`called Terminate,`,props)
    // Firebase
    await update(COLLECTION.AFFILIATES,props.id,value);
    let theUpdate = {...campaignState,...value}
    // User
    updateCampaign(theUpdate);
    // Local
    UpdateState(theUpdate);
  }
  return (
    <Card >
   
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "5px",
          marginTop:-15
        }}
      >
        <span style={{ fontSize: "1.5rem", fontWeight: "700" }}>
          {campaignState.title}
        </span>
        <span style={{ fontSize: "1.25rem", fontWeight: "700" }}>
          <svg height="10" width="12">
            <circle cx="5" cy="5" r="5" fill={colors[campaignState.status]} />
          </svg>
          <span> {campaignState.status}</span>
        </span>
      </div>

      <div
      style={{width:'60%'}}
        // style={{
        //   fontSize: "1.0rem",
        //   fontWeight: "500",
        //   textAlign: "justify",
        //   marginBottom: "20px"
        // }}
      >
        {campaignState.description}
      </div>

      <div style={{ marginBottom: "30px", display: "flex", textAlign: "left" }}>
        {props.attachments.map((attachment) => {
          return (
            <a
              key={attachment.name + attachment.link}
              style={{
                fontSize: "1.5rem",
                fontWeight: "700",
                marginRight: "20px"
              }}
              href={attachment.link}
            >
              {attachment.name}
            </a>
          );
        })}
      </div>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          label="Edit Campaign"
          size="small"
          
          style={{ marginRight: "20px",height:40,fontSize:12 }}
          onClick={() => {
            console.log("edit campaing button");
            setEditModalVisible(true);
          }}
        />
        <Button
        style={{height:40,fontSize:12}}
          label="Terminate Campaign"
          severity="danger"
          onClick={() => setTerminateModalVisible(true)}
        />
      </div>

      <TerminateModal
        visible={terminateModalVisible}
        setVisible={setTerminateModalVisible}
        onClick = {()=>{
          terminate()
          setTerminateModalVisible(false)
        }
      
        }
      />
      <EditModal
        visible={editModalVisible}
        state= {campaignState}
        setVisible={setEditModalVisible}
        attachments={props.attachments}
        updateState={updateDetails}
      />
    </Card>
  );
}