import React, { useContext, useState, useEffect } from "react";
import { db, auth, storageRef } from "../firebase";
import firebase from "firebase";
import {
  collectAffiliates,
  getAllUserCampaigns,
} from "../data/CampaignService";
const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(); // login auth
  const [user, setUser] = useState(); // firestore

  /**
   * SaaS
   * @param {*} email
   * @param {*} password
   * @returns
   */
  function signupWithEmail(email, password) {
    return auth
      .createUserWithEmailAndPassword(email, password)
      .then(async (cred) => {
        const docRef = db.collection("admin").doc(email);

        // Check if the document exists
        docRef
          .get()
          .then((doc) => {
            if (doc.exists) {
              const data = doc.data();
              console.log("doc.exists: Document data:", data);
              docRef.update({
                uid: cred.user.uid,
                email: email,
                affiliatedCampaignIds: [],
                campaignIds: [],
              });
            } else {
              console.log("Document does not exist");
              docRef.set({
                uid: cred.user.uid,
                email: email,
                affiliatedCampaignIds: [],
                campaignIds: [],
                communications: [],
              });
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
      });
  }
  function signupWithEmailV1(email, password) {
    return auth
      .createUserWithEmailAndPassword(email, password)
      .then(async (cred) => {
        console.log(`cred: ${JSON.stringify(cred)}`);
        // purposely leave out communications in case they exist already
        // as we do not want to overwrite
        // open question to wether communications should be communicationIds
        return db
          .collection("admin")
          .doc(email)
          .set({
            uid: cred.user.uid,
            email: email,
            affiliatedCampaignIds: [],
            campaignIds: [],
          })
          .then(() => {
            console.log("Document successfully written");
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
      });
  }
  function login(email, password) {
    return new Promise((resolve, reject) => {
      resolve(auth.signInWithEmailAndPassword(email, password));
    });
  }

  async function getUser(uid) {
    const user = await db.collection("admin").doc(uid).get();
    const userData = user.data();
    console.log(`getUser:userData`, userData);
    let promises = [];
    userData.promotionIds.forEach((promoId) => {
      console.log("getUser:promtionId", promoId);
      promises.push(db.collection("promotions").doc(promoId).get());
    });

    // send request to get all promotions the user has an id for
    let promotionsArray = await Promise.all(promises);
    let usersPromotions = [];
    promotionsArray.forEach((promo) => {
      console.log("getUser:promo.data()", promo.data());
      usersPromotions.push(promo.data());
    });
    console.log(`getUser:db:user, ${JSON.stringify(user.data())}`);
    const supportersCount = await db
      .collection("supportersCount")
      .doc(uid)
      .get();
    console.log(
      `getUser:supporterCount, ${JSON.stringify(supportersCount.data())}`
    );
    setUser({
      ...user.data(),
      uid,
      ...supportersCount.data(),
      promotions: usersPromotions,
    });
    return;
  }

  /**
   * SaaS
   * @param {*} email
   * @param {*} password
   * @returns
   */

  async function getUserCampaigns(email) {
    let usersCampaigns = [];
    usersCampaigns = await getAllUserCampaigns(email);
    await collectAffiliates(usersCampaigns);
    return usersCampaigns;
  }

  async function getUserByEmail(email) {
    console.log("getUserByEmail", email);
    const user = await db.collection("admin").doc(email).get();
    const userData = user.data();
    console.log(`getUser:userData`, userData);

    // let promises = [];
    // userData.campaignIds.forEach((campaignId) => {
    //   console.log("getUser:promtionId", campaignId);
    //   promises.push(db.collection("campaigns").doc(campaignId).get());

    // });
    // let campaignsArray = await Promise.all(promises);
    let usersCampaigns = await getUserCampaigns(email);
    console.log(`getUserByEmail:usersCampaigns`, usersCampaigns);
    // Get affiliated campaigns data
    let promises = [];
    userData.affiliatedCampaignIds.forEach((campaignId) => {
      console.log("getUser:promtionId", campaignId);
      promises.push(db.collection("campaigns").doc(campaignId).get());
    });
    let affiliatedCampaignArray = await Promise.all(promises);
    let usersAffiliatedCampaigns = [];

    affiliatedCampaignArray.forEach((campaign) => {
      console.log(
        "getUser:affiliatedCampaignArray:campaign.data()",
        campaign.data()
      );
      usersAffiliatedCampaigns.push(campaign.data());
    });

    let invitedCampaigns = [];
    let invitesRef = await db
      .collection("campaigns")
      .where("pendingInvites", "array-contains", email)
      .get();
    invitesRef.forEach((doc) => {
      invitedCampaigns.push(doc.data());
    });

    setUser({
      ...user.data(),
      userCampaigns: usersCampaigns,
      affiliatedCampaigns: usersAffiliatedCampaigns,
      invitedCampaigns: invitedCampaigns,
      // uid,
      // ...supportersCount.data(),
      // promotions: usersPromotions,
    });
    return;
  }
  function updateCampaign(campaign) {
    console.log(`updateCampaign:user:`, user);
    console.log(`updateCampaign:currentUser:`, currentUser);

    // orginal
    let campaignsArray = user.userCampaigns;
    // the campaign that needs to be updated
    let campaignList = campaignsArray.filter(
      (c) => c.campaignId == campaign.campaignId
    );

    // the needed update to the campaign
    if (campaignList.length) {
      // orginal without the campaign
      campaignsArray = campaignsArray.filter(
        (c) => c.campaignId != campaign.campaignId
      );
      let newCampaign = { ...campaignList[0], ...campaign };
      // add updated campaing back to user list
      campaignsArray.push(newCampaign);
      // create a new user object
      let newUser = { ...user, userCampaigns: campaignsArray };
      console.log("updateCampaign:newUser", newUser);
      // call hook responsible for updating the user
      setUser(newUser);
    }
    console.log(`updateCampaign:campaignsArray, `, campaignsArray);
  }

  function logout() {
    return auth.signOut();
  }
  function resetPassword(email) {
    console.log(`email ---------------- ${email}`);
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }
  // async function updateUsername(_username) {
  //   let newState = { ...user };
  //   newState.username = _username;
  //   setUser(newState);
  //   await db
  //     .collection("admin")
  //     .doc(currentUser.uid)
  //     .update({ username: _username });
  // }
  // async function updateBio(_bio) {
  //   let newState = { ...user };
  //   newState.bio = _bio;
  //   setUser(newState);
  //   await db.collection("admin").doc(currentUser.uid).update({ bio: _bio });
  // }
  async function photoUploadBackend(file) {
    var metadata = {
      contentType: file.type,
    };
    console.log(`photoUploadBackend`);
    // Upload file and metadata to the object 'images/mountains.jpg'
    var uploadTask = storageRef
      .child("images/" + currentUser.uid)
      .put(file, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...
          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        // And update user database to use new url
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log("File available at", downloadURL);
          const newuser = {
            bio: user.bio,
            links: user.links,
            photoUrl: downloadURL,
            username: user.username,
          };

          db.collection("admin").doc(currentUser.uid).update(newuser);
          console.log(`user: ${JSON.stringify(newuser)}`);
          setUser(newuser);
        });
      }
    );
  }

  function verifyEmail() {
    return auth.currentUser.sendEmailVerification();
  }
  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      console.log(`useEffect:currentUser`, JSON.stringify(currentUser));
      setCurrentUser(currentUser);
    });
    return unsubscribe;
  }, []);

  const value = {
    getUser,
    setUser,

    verifyEmail,
    currentUser,
    user,
    signupWithEmail,
    login,
    logout,
    resetPassword,
    updatePassword,
    updateEmail,
    photoUploadBackend,
    setCurrentUser,
    getUserByEmail,
    updateCampaign,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
