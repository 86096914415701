import React from "react";
import { Card } from "primereact/card";
import { FaList } from "react-icons/fa";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import {style} from './CardStyles'
import { CardHeader } from "@material-ui/core";
export default function DetailsCard(props) {


  return (
    <Card style={style.formCard}>
      <FaList size={"25"} style={style.headerIcon} />

      <div
        className="details-card"
        style={style.cardBody}
      >
        <h2 style={style.cardHeader}>Campaign Details</h2>
      <div style = {style.cardSubBody}>
        <h4>Name</h4>
        <InputText 
        style ={style.cardInput}
        value={props.state.title} onChange={(e) => {
            props.update({title:e.target.value})
          }} />

        <h4>Site/Product Url</h4>
        <InputText
          value={props.state.url}
          style ={style.cardInput}
          onChange={(e) => {
            props.update({url:e.target.value})
          }}
          cols={30}
        />

        <h4>Description</h4>
        <InputTextarea
          value={props.state.description}
          onChange={(e) => {
            console.log('updateDescription')
            // setDescription(e.target.value)
            props.update({description:e.target.value})
          }}
          rows={5}
          cols={50}
        />
        </div>
      </div>
      
    </Card>
  );
}
// const style = {
//   cardContainer:{ textAlign: "start", margin: "0px 80px" ,marginTop:-30},
//   formCard:{
//     position: "relative",
//     maxWidth: '800px',
//     minWidth: '490px',
//     margin: '0 auto',
//   },
//   formCardHeader:{
//     marginLeft:-10
//   }
// }