import React, { Component } from "react";
// import '../components/ProfileHeaderStyles.css'
import "../css/AboutScreenStyles.css";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import "primeflex/primeflex.css";
import { Container, Row, Col } from "react-bootstrap";
import Offers from "../assets/images/Offers.png";
import Support from "../assets/images/Support.png";


export default class ErrorScreen extends Component {
  render() {
    const styles = {
      backgroundColor: '#282b30',
      color: '#d9e3ea'
    };


    return (
      <header id="header" className="ex-2-header" style={{padding:"5rem", backgroundColor:"#282b30"}}>
        <div  style={styles}>
          <h1>Oops! 404 Error</h1>
          <p>We couldn't find the page you were looking for. It's possible that the promotion has been moved, deleted or never existed in the first place.</p>
        </div>
      </header>
    );
  }
}
