import { Button } from 'primereact/button';
import CommunicationsDataRenderer from '../DataRender/CommunicationsDataRender.jsx';

function Header(props){
    if(props.buttonTitle){
        return(
        <div style={styles.headerRow}>
         <h1>{props.title}</h1>
         <Button onClick={props.buttonHandler} icon="pi pi-plus" label={props.buttonTitle}  style={{marginTop:'10px',height:'40px'}}/>
        </div> 
        )
    } else {
        return(
        <h1>{props.title}</h1>
        )
    }
    
 
}

function CommunicationPanel (props){
    console.log("CommunicationsPanel:props.data:", props.data)
    console.log("CommunicationsPanel:props.test:", props.test)

    return(
    <div style = {{marginLeft:'80px',marginRight:'80px'}}>
    <Header {...props}/>
     {  props.data && props.data.length ?
        <CommunicationsDataRenderer data = {props.data } />:
        <h5 style={styles.emptyMessage}>{props.emptyMessage}</h5> 
        }
  </div>
  )
    
 }
 const styles = {
    emptyMessage:{
        paddingLeft:'20px'
    },
    headerRow:{
        display: 'flex',
        justifyContent: 'space-between',
        alignContent:'center',
        // backgroundColor:'white',
        height:'60px',
        alignItems: 'center'

    }
}
export default CommunicationPanel;