import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

export default function TerminateModal(props) {
  return (
    <Dialog
      visible={props.visible}
      style={{ textAlign: "center" }}
      onHide={() => props.setVisible(false)}
    >
      <div style={{ margin: "40px", fontSize: "30px", fontWeight: "600" }}>
        <div>Are you sure you want to terminate this campaign?</div>
        <div>This action cannot be undone.</div>
        <br />
        <Button onClick= {props.onClick}label="Terminate Campaign" severity="danger" />
        <br />
      </div>
    </Dialog>
  );
}
