import React, { useRef, useState, useEffect } from "react";
import { Toast } from "primereact/toast";
import Navbar from "../components/Navbar/Navbar";
import "firebase/auth";
import { useAuth } from "../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";

export default function SignUpScreen() {
  let severity = "error";
  let summary = "Failed to sign up";
  let detail = "Be sure to use a valid email";
  const emailRef = useRef();
  const passwordRef = useRef();
  const { auth, currentUser, signupWithEmail, verifyEmail } = useAuth();
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const history = useHistory();
  const showMessage = (severity, summary, detail) => {
    toast.current.show({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 9000,
    });
  };

  async function handleSubmit() {
    console.log(`handleSubmit ${emailRef}`);
    // e.preventDefualt();
    try {
      setLoading(true);
      await signupWithEmail(emailRef.current.value, passwordRef.current.value);
      summary = "Failed to send email verification";
      await verifyEmail();
      severity = "info";
      summary = "Email verification sent";
      detail = "Check email for verification link";
      showMessage(severity, summary, detail);
      setTimeout(() => {
        history.push("/signin");
      }, 3000);
    } catch (e) {
      console.log(`catch signup ${JSON.stringify(e)}`);
      showMessage(severity, summary, e.message);
    }

    setLoading(false);
  }

  return (
    <div>
      <Toast ref={toast} position="bottom-right" />
      <Navbar bgColor="#5f63ba"/>

      <header id="header" className="ex-2-header" style={{padding:"5rem"}}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Sign Up</h1>
              <p>
                Already signed up? Then just{" "}
                <Link
                  className="white"
                  style={{ fontWeight: "bold" }}
                  to="/signin"
                >
                  Login
                </Link>
              </p>
              {/* <!-- Sign Up Form --> */}
              <div className="form-container">
                <div className="form-group">
                  <input
                    type="email"
                    ref={emailRef}
                    className="form-control-input notEmpty"
                    id="lemail"
                    required
                  />
                  {/* <label className="label-control" for="lemail">Email</label> */}
                  <label className="label-control">Email</label>
                  <div className="help-block with-errors"></div>
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    ref={passwordRef}
                    className="form-control-input notEmpty"
                    id="lpassword"
                    required
                  />
                  {/* <label className="label-control" for="lpassword">Password</label> */}
                  <label className="label-control">Password</label>
                  <div className="help-block with-errors"></div>
                </div>
                <div className="form-group">
                  <button
                    disabled={loading}
                    type="submit"
                    className="form-control-submit-button"
                    onClick={handleSubmit}
                    id="signup-button"
                  >
                    SIGN UP
                  </button>
                </div>
                <div className="form-message">
                  <div id="smsgSubmit" className="h3 text-center hidden"></div>
                </div>
              </div>
              {/* <!-- end of sign up form -->
                    <a className="white" href="account-recovery.html">Forgot your password ?</a> */}
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
