import { Card } from "primereact/card";
import { Button } from "primereact/button";
import {useAuth} from '../../contexts/AuthContext';
import { db} from "../../firebase";
import firebase from "firebase";
import {acceptInvite, getCampaign, declineInvite } from "../../data/CampaignService";


function abbreviateNumber(value) {
  var newValue = value;
  if (value >= 1000) {
    var suffixes = ["", "K", "M", "B", "T"];
    var suffixNum = Math.floor(("" + value).length / 3);
    var shortValue = "";
    for (var precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum != 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(precision)
      );
      var dotLessShortValue = (shortValue + "").replace(/[^a-zA-Z 0-9]+/g, "");
      if (dotLessShortValue.length <= 2) {
        break;
      }
    }
    if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
    newValue = shortValue + suffixes[suffixNum];
  }
  return newValue;
}

export default function InviteCard(props) {
  
  const {logout,user, setUser} = useAuth();

  const handleAccept = async () => {
    console.log(props)
    console.log("InviteCard:handleAccept:user", user)
    console.log("accepted invite")
    acceptInvite(props.campaignId, user);
    const newCampaignData = await getCampaign(props.campaignId)
    const newCommunications = user.communications.filter((communication) => communication.campaignId != props.campaignId)
    setUser({...user, communications : newCommunications, affiliatedCampaigns : [...user.affiliatedCampaigns, newCampaignData] })
  }

  const handleDecline = async () => {
    console.log("rejected invite")
    declineInvite(props.campaignId, user)
    const newCommunications = user.communications.filter((communication) => communication.campaignId != props.campaignId)
    setUser({...user, communications : newCommunications})
  }

  return (
    <Card>
      <div style={{ display: "flex" }}>
        <div
          className="campaign-card-left"
          style={{ flex: "1 1 0", textAlign: "left" }}
        >
          <h3>{props.title}</h3>
        </div>
        <div
          className="campaign-card-right"
          style={{ display: "flex", alignItems: "flex-end", flex: "1.75 1 0" }}
        >
          <div style={{ flex: "1 1 0" }}>
            <h4>Budget</h4>
            <div>${abbreviateNumber(props.budget)}</div>
          </div>
          <div style={{ flex: "1 1 0" }}>
            <h4>CPC</h4>
            <div>${abbreviateNumber(props.cpc)}</div>
          </div>
          <div style={{ flex: "1 1 0" }}>
            <Button
              label="Accept"
              severity="success"
              style={{ marginRight: "5px" }}
              onClick={handleAccept}
            />
            <Button label="Decline" severity="danger" onClick={handleDecline}/>
          </div>
        </div>
      </div>
    </Card>
  );
}
