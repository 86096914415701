import React from "react";
import LogoImage from "../images/logobig.png";
import { Link, useHistory, NavLink } from "react-router-dom";
import { Button } from 'primereact/button';
import * as ReactBootStrap from "react-bootstrap";
import "./NavbarStyles.css";

export default function Navbar({bgColor}) {

  return (
   <>
    <div className="fluid-container" style={{backgroundColor:bgColor, color:"#d9e3ea", padding:"2.5% 5%", display: "flex", justifyContent: "space-between", alignItems:"center"}}>
          <Link
            style={{ textDecoration: "none", fontSize: 30 }}
            className="navbar-brand logo-image"
            to="/"
          >
            <text className={"Logo"}>Affy</text>
          </Link>

          <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"30px"}}>
          <Link className="textStyleLink" to="/signup">
              <text className="textStyle">Sign up</text>
            </Link>
            <Link to="/signin">
              <Button style={{borderRadius:"0px", color: "#d9e3ea"}}  label="Sign in" />
            </Link>
          </div>

        </div>
   </>
  );
}
