import React from "react";

function Navbar(props) {
  return (
    <div style={styles.navContainer}>
      <div style={styles.leftContainer}>
        <text onClick={props.leftClick} style={styles.logoText}>
          Affy
        </text>
        <div style={styles.verticalBorder} />

        <text style={styles.pageTitle}>{props.pageTitle}</text>
      </div>
      <div style={styles.rightContainer}>
        <text
          className="rightContent"
          onClick={props.rightClick}
          style={styles.rightNavText}
        >
          {props.rightNavTitle}
        </text>
        <style>
          {`
      @media only screen and (max-width: 500px) {
        .rightContent {
          display: none;
        }
      }
    `}
        </style>
      </div>
    </div>
  );
}
const styles = {
  navContainer: {
    backgroundColor: "#EFEFEF",
    width: "100%",
    left: "0px",
    top: "0px",
    position: "absolute",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    display: "flex",
    justifyContent: "space-between"
  },
  leftContainer: {
    marginLeft: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  logoText: {
    fontFamily: "Pacifico",
    lineHeight: "70.24px",
    fontSize: "40px",
    marginRight: "10px",
    marginBottom: "10px",
    color:'black'
  },
  pageTitle: {
    marginLeft: "10px",
    marginRight: "10px",
    fontFamily: "Amiko",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "30px",
    lineHeight: "40px",
    color:'black'
  },
  verticalBorder: {
    height: "80%",
    borderRight: "2px solid black"
  },
  rightContainer: {
    marginRight: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  rightNavText: {
    marginRight: "10px",
    fontFamily: "Amiko",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "40px"
  }
};
export default Navbar;
