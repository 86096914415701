 class  ToastUtil {
  public toast: any;
  public static instance: ToastUtil;
     static toast: any;

  constructor() {}
  public static getInstance() {
    if (!this.instance) {
      this.instance = new ToastUtil();
    }

    return this.instance;
  }
  public setToast(ref) {
    this.toast = ref;
  }
  public showMessage = (severity, summary, detail) => {
    this.toast.current.show({
      severity: `${severity}`,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  };
}
export default ToastUtil.getInstance();
