import { Card } from "primereact/card";
import ToastUtil from "../ToastUtil.js/ToastUtil";
import "./CampaignCard.css";
import { BsClipboard } from "react-icons/bs";
import {getAffiliatedUrl} from "../../utils/CampaignUtils";
// import {useAuth} from '../contexts/AuthContext';
import {useAuth} from '../../contexts/AuthContext';

function abbreviateNumber(value) {
  var newValue = value;
  if (value >= 1000) {
      var suffixes = ["", "K", "M", "B","T"];
      var suffixNum = Math.floor( (""+value).length/3 );
      var shortValue = '';
      for (var precision = 2; precision >= 1; precision--) {
          shortValue = parseFloat( (suffixNum != 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
          var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
          if (dotLessShortValue.length <= 2) { break; }
      }
      if (shortValue % 1 != 0)  shortValue = shortValue.toFixed(1);
      newValue = shortValue+suffixes[suffixNum];
  }
  return newValue;
}

const colors = {
  Active : "green",
  Ended: "red",
  Terminated: "blue"
}

export default function AffiliateCard(props) {
  const { currentUser } = useAuth();

  console.log('AffiliateCard:props:',props)
  return (
    <Card>
      <div style={{ display: "flex",marginTop:-40 }}>
        <div
          className="campaign-card-left"
          style={{ flex: "1 1 0"}}
        >
          <h3>{props.title}</h3>
          <div>
            <span>Status: </span>
            <span>
              <svg height="10" width="10">
                <circle cx="5" cy="5" r="5" fill={colors[props.status]} />
              </svg>
            </span>
            <span> {props.status}</span>
          </div>
        </div>
        <div
          className="campaign-card-right"
          style={{ display: "flex", alignItems: "flex-end", flex: "1.75 1 0" }}
        >
         <div style={{ flex: "1 1 0" ,textAlign:'center'}}>
            <h5>Clicks</h5>
            <text>{abbreviateNumber(props.clicks)}</text>
          </div>
          <div style={{ flex: "1 1 0",textAlign:'center' }}>
            <h5>Earnings</h5>
            <text>-</text>
          </div>
          <div style={{ flex: "0 0 0" }}>
          <button onClick={(e)=>{
               e.stopPropagation(); 
              ToastUtil.showMessage('success','copied to clipboard')
              navigator.clipboard.writeText(getAffiliatedUrl(props.id,currentUser.uid));

            }} > <BsClipboard size = {22} style={{ color: "#5F63BA"}} /></button>
          </div>
 
         
        </div>
      </div>
    </Card>
  );
}
