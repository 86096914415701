import Navbar from "../../components/Nav/Navbar";
import { useHistory,useLocation } from 'react-router-dom';
import PanelSingleCard from "../../components/Panels/PanelSingleCard";
import ManageAffiliatesCard from "../../components/Cards/ManageAffiliatesCard/ManageAffiliatesCard";
import ManageDetailsCard from "../../components/Cards/ManageDetailsCard/ManageDetailsCard";
import {screenStyles} from '../ScreenStyles'
import QRCode from "react-qr-code";
import { Card } from "primereact/card";
import { useEffect } from "react";
import {getAffiliatedUrl} from '../../utils/CampaignUtils';

function QR(props){
  console.log('QR:props',props)
  // const link = getAffiliatedUrl(props.)
  const link = getAffiliatedUrl(props.id,props.QR)
  return( 
    <Card>

   
   
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "5px",
          marginTop:-15

        }}
      >
         <QRCode value= {link}/>
        </div>
        </Card>

  )
}
export default function ManageCampaignScreen() {
console.log('ManageCampaignScreen',JSON.stringify())
const location = useLocation();
const myProp = location.state && location.state;
console.log('ManageCampaignScreen:props',myProp)
  const history = useHistory();
  return (
    <div style={{ flex:1,paddingTop: "80px",backgroundColor:"#EFEFEF", width: '100%',
    height: '100%', }}>
      <Navbar
        leftClick={() => {
          console.log("hello");
          history.push("/dashboard")
        }}
        pageTitle={"Manage Campaign"}
      />
      <div style = {screenStyles.body}>
      <PanelSingleCard {...myProp} component={ManageDetailsCard} panelTitle = {"Details"}/>
      <PanelSingleCard {...myProp} panelTitle = {"Affiliates"} component={ManageAffiliatesCard}/>
      <PanelSingleCard {...myProp} panelTitle = {"Market With QR"} component={QR}/>

      <div style={{padding:'200px'}}></div>
 
      

      </div>
    
    </div>
  );
}
