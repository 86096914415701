import React, { useEffect } from 'react';

function DataRenderer({ component: Component, data }) {
  useEffect(()=>{
  },[])
    return (
      <div>
        {data.map((item, index) => (
          <div style = {{marginBottom:'20px'}}>
            <Component key={index} {...item} />
          </div>
        ))}
      </div>
    );
  }
  
  export default DataRenderer;
