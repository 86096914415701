import React, { useEffect } from 'react';
import InviteCard from '../Cards/InviteCard';

class CommunicationFactory {
    static getCommunicationComponent(index, item){
          return (
            < InviteCard key={index} {...item} />
          )
    }
}

function CommunicationsDataRenderer({ data }) {
  useEffect(()=>{
    console.log('DataRenderer:',JSON.stringify(data))
  },[])
    return (
      <div>
        {data.map((item, index) => (
          <div style = {{marginBottom:'20px'}}>
            {CommunicationFactory.getCommunicationComponent(index,item)}
          </div>
        ))}
      </div>
    );
  }
  
  export default CommunicationsDataRenderer;
