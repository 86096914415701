import { Card } from "primereact/card";
import { RiAttachment2 } from "react-icons/ri";
import { FileUpload } from 'primereact/fileupload';
import {style} from './CardStyles';
export default function AttachmentsCard(props) {


  return (
    <Card style={style.formCard}>
      <RiAttachment2 size={"30"} style={style.headerIcon} />

      <div
        className="details-card"
        style={style.cardBody}
      >
        <h2 style ={{marginLeft:-15}}>Attachments</h2>
        <div style = {style.cardSubBody}>
        <h4 style={{color:"grey"}}>Upload relevant marketing material such as scripts, videos, and images that affiliates should use.</h4>

    
<FileUpload name="demo[]" url={'/api/upload'} multiple accept="image/*" maxFileSize={1000000} emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} />

        </div>
     
      </div>
    </Card>
  );
}
